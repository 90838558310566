<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7" class="text-right">
        <DatePicker
          type="daterange"
          placeholder="时间范围"
          @on-change="filterDate"
          style="width: 250px"
        ></DatePicker>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        user_id: "用户ID"
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "用户",
          key: "user",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    if (params.row.user) {
                      this.$router.push("/user/detail/" + params.row.user.id);
                    }
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.user
                      ? params.row.user.avatar
                        ? params.row.user.avatar
                        : require("@/assets/no-avatar.jpg")
                      : "",
                    width:'48',
                    height:'48'
                  },
                  class: "avatar mr"
                }),
                h("p", params.row.user ? params.row.user.nickname : "无")
              ]
            );
          }
        },
        {
          title: "类型",
          key: "feedback_type",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            switch (params.row.feedback_type) {
              case 0:
                return h("Tag", { props: { color: "orange" } }, "优化建议");

              case 1:
                return h("Tag", { props: { color: "green" } }, "使用问题");

              case 2:
                return h("Tag", { props: { color: "cyan" } }, "投诉");

              case 3:
                return h("Tag", { props: { color: "gold" } }, "其他");

              default:
                return h("Tag", "-");
            }
          }
        },
        {
          title: "内容",
          key: "content",
          sortable: "custom",
          align: "center",
          minWidth: 240,
          render: (h, params) => {
            return h("span", params.row.content.substr(0, 15));
          }
        },
        {
          title: "联系方式",
          key: "contact_way",
          sortable: "custom",
          align: "center",
          minWidth: 140
        },
        {
          title: "提交时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["UserFeedback", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }
            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        begin_date: this.begin_date,
        end_date: this.end_date,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/userFeedback/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    filterDate(date) {
      this.begin_date = date[0];
      this.end_date = date[1];
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    update(item) {
      this.$router.push("/user-feedback/update/" + item.id);
    }
  }
};
</script>